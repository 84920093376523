import { useState, createContext, useEffect, useContext } from "react";
import { BASE_URL } from "../requests";

export interface User {
  id: number;
  email: string;
  name: string;
  isPending: false;
}

export interface PendingUser {
  isPending: true;
}

export type LoginFunction = (email: string, password: string) => Promise<void>;

export interface Auth {
  user: User | PendingUser | null;
  login: LoginFunction;
  logout: () => void;
  checkAuth: () => Promise<User | null>;
}

export const AuthContext = createContext<Auth>({
  user: { isPending: true },
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  checkAuth: () => Promise.resolve(null),
});

export const AuthProvider = (props: any) => {
  const [user, setUser] = useState<User | PendingUser | null>({ isPending: true });

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      setUser(null);
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (!user.isPending) {
        localStorage.setItem("user", JSON.stringify(user));
      }
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  const login = (name: string, password: string) => {
    console.log(`login: ${name}`);

    const query = new URLSearchParams({ name, password });

    return Promise.resolve()
      .then(() =>
        fetch(`${BASE_URL}/login/login?${query}`, {
          method: "GET",
        })
      )
      .then((response) => response.json())
      .then((data) => {
        console.log("logged in", data);
        setUser({ isPending: false, ...data });
      });
  };

  const logout = () => {
    setUser(null);
    return Promise.resolve()
      .then(() => fetch(`${BASE_URL}/login/logout`))
      .then((response) => response.text())
      .then((data) => {
        console.log("logged out", data);
      });
  };

  const checkAuth = () => {
    if (import.meta.env.DEV) {
      return Promise.resolve(user);
    }

    return fetch(`${BASE_URL}/login/check`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("check auth", data);
        return data;
      })
      .catch((error) => {
        setUser(null);
        console.log("check auth error", error);
        return null;
      });
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, checkAuth }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuth(): Auth {
  return useContext(AuthContext);
}
